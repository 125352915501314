import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import WorkItem from "./workItem";
import Eyebrow from "./eyebrow";
import Button from "./button";
import tr from "../data/tr.json";
import en from "../data/eng.json";
import { useLanguage } from "../context/LanguageContext";

const Works = () => {
  const data = useStaticQuery(graphql`
    {
      allWorksJson {
        nodes {
          id
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 592
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF, JPG]
              )
            }
          }
        }
      }
    }
  `);
  const { language } = useLanguage();

  const newData = data.allWorksJson.nodes.map((node, index) => {
    const headerKey = `quarryHeader${index + 1}`;
    const textKey = `quarryText${index + 1}`;
    return {
      ...node,
      title: language === "tr" ? tr[headerKey] : en[headerKey],
      description: language === "tr" ? tr[textKey] : en[textKey],
    };
  });

  return (
    <div id="ourwork">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <div
            className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-10 items-center"
            style={{ alignItems: "self-start" }}
          >
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow
                  label={language === "tr" ? tr.worksHeader : en.worksHeader}
                />
                <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
                  {language === "tr" ? tr.worksSubHeader : en.worksSubHeader}
                </h3>
              </div>
              {newData.slice(0, 1).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                  description={node.description}
                />
              ))}
            </div>

            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {newData.slice(1, 3).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                  description={node.description}
                />
              ))}
            </div>

            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 ">
              <div className="flex flex-col gap-6">
                {newData.slice(3, 4).map((node) => (
                  <WorkItem
                    key={node.id}
                    image={getImage(node.image)}
                    title={node.title}
                    description={node.description}
                  />
                ))}
              </div>
            </div>

            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {newData.slice(4, 6).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                  description={node.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
